import React from 'react';
import NavigationIcons from './NavigationIcons';
import UserProfile from './UserProfile';

function HeaderRight({ isLoggedIn, userName }) {
  return (
    <div className="flex justify-end items-center space-x-4">
      <NavigationIcons />
      <UserProfile isLoggedIn={isLoggedIn} userName={userName} />
    </div>
  );
}

export default HeaderRight;