import React from 'react';
import { FaEye, FaInfoCircle, FaCheckCircle, FaEdit, FaComments } from 'react-icons/fa';

function OptionsBar() {
  return (
    <div className="options-bar flex justify-end space-x-2 pr-4">
      <FaEye className="icon" />
      <FaInfoCircle className="icon" />
      <FaCheckCircle className="icon" />
      <FaEdit className="icon" />
      <FaComments className="icon" />
    </div>
  );
}

export default OptionsBar;