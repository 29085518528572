import React from 'react';

function CommentField({ comment }) {
  return (
    <div className="comment">
      {comment}
    </div>
  );
}

export default CommentField;