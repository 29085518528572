import React from 'react';

function TitleSection({ mainTitle, subTitle }) {
    return (
      <div className="flex items-center space-x-2">
        <h1 className="text-lg">{mainTitle}</h1>
        {subTitle && <span className="text-text dark:text-dark-text font-bold border border-icon py-0.5 px-0.5 rounded text-xs">{subTitle}</span>}
      </div>
    );
  }

export default TitleSection;
