import { useState, useEffect } from 'react';

function useResponsivePlayerStyle() {
  const [playerStyle, setPlayerStyle] = useState({});

  useEffect(() => {
    function handleResize() {
      if (window.innerWidth >= 768) { // md breakpoint
        setPlayerStyle({
          maxHeight: '60vh',
          maxWidth: 'calc(60vh * (16 / 9))',
          margin: 'auto',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          height: 'auto'
        });
      } else {
        setPlayerStyle({});
      }
    }

    // Figyeljük a képernyő átméretezését
    window.addEventListener('resize', handleResize);
    // Kezdeti stílus beállítása
    handleResize();

    // Eseményfigyelő eltávolítása a komponens lecsatolásakor
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return playerStyle;
}

export default useResponsivePlayerStyle;