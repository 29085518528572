import React from 'react';
import HLSVideoPlayer from './HLSVideoPlayer'; // Az új HLSVideoPlayer importálása

function VideoPlayer({ videoUrl }) {
  return (
    <div className="video-container w-full relative">
      <HLSVideoPlayer source={videoUrl} /> {/* HLSVideoPlayer használata az adott URL-lel */}
    </div>
  );
}

export default VideoPlayer;