import React from 'react';

function UserProfile({ isLoggedIn, userName }) {
  return (
    <div className="flex items-center space-x-2">
      {isLoggedIn ? (
        <span className="text-text font-medium">{userName}</span>
      ) : (
        <div className="w-8 h-8 rounded-full border border-primary flex justify-center items-center">
          <span className="text-text dark:text-dark-icon font-medium">MP</span>
        </div>
      )}
    </div>
  );
}

export default UserProfile;