import React from 'react';

function Button({ label, onClick, icon: Icon }) {
  return (
    <button
      className="custom-button"
      onClick={onClick}
    >
      {Icon && <Icon className="custom-button-icon" />}
      {label}
    </button>
  );
}

export default Button;