import React from 'react';
import Header from './components/UI/Header';
import VideoPlayer from './components/function/VideoPlayer';
import Button from './components/UI/Button';
import OptionsBar from './components/UI/OptionsBar';
import Footer from './components/UI/Footer';
import CommentField from './components/UI/CommentField';
import { FaDownload } from 'react-icons/fa'; // Importáld az ikont
import useResponsivePlayerStyle from './hooks/useResponsivePlayerStyle';

function HomePage({ videoData }) {
  const playerStyle = useResponsivePlayerStyle();
  const comment = "Ez egy hosszabb szöveg, amely több sorba tördelődik, ha szükséges.";

  const handleDownload = () => {
    const link = document.createElement('a');
    link.href = videoData.downloadUrl;
    link.download = videoData.mainTitle; // A fájl neve, opcionálisan a videoData-ból származó névvel
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <div className="main-content">
      <Header mainTitle={videoData.mainTitle} />
      <div>
        <div className="relative" style={playerStyle}>
          <VideoPlayer videoUrl={videoData.videoUrl} />
          <div className="absolute -bottom-8 right-0 mt-4">
      <OptionsBar />
    </div>
        </div>
      </div>
      <div className="flex justify-center mb-4 mt-14 md:mt-8 md:mb-6"> {/* Középre igazítás */}
        <Button 
          label="Letöltés"
          onClick={handleDownload}
          icon={FaDownload}
        />
      </div>
      <div className="flex-grow mb-4">
        <CommentField comment={comment}/>
      </div>
      <Footer />
    </div>
  );
}

export default HomePage;