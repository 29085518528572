import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import HomePage from './HomePage';
// import VideoPlayer from './components/function/VideoPlayer';

function JobPage() {
  let { jobID } = useParams();
  const [videoData, setVideoData] = useState(null);

  useEffect(() => {
    fetch(`https://ebum3ts09i.execute-api.eu-north-1.amazonaws.com/hls/video/${jobID}`)
      .then(response => response.json())
      .then(data => {
        // Itt történik az adatok átkonvertálása
        const convertedData = {
          mainTitle: data.FileName,
          downloadUrl: data.OriginalFilePath,
          videoUrl: data.HLSManifestPath
        };
        setVideoData(convertedData);
      })
      .catch(error => console.error('Error fetching data: ', error));
  }, [jobID]);

  if (!videoData) {
    return <div>Betöltés...</div>;
  }

  return (
    <div>
        {videoData && <HomePage videoData={videoData} />}
    </div>
  );

  // return (
  //   <div>
  //     <AppBar position="static">
  //       <Toolbar>
  //         <Button color="inherit" href={videoData.OriginalFilePath} download>
  //           Letöltés
  //         </Button>
  //       </Toolbar>
  //     </AppBar>
  //     <h2>{videoData.FileName}</h2>
  //     <VideoPlayer manifestPath={videoData.HLSManifestPath} />
  //     <p>Kezdési idő: {videoData.StartTime}</p>
  //     <p>Érvényesség: {videoData.Validity}</p>
  //   </div>
  // );
}

export default JobPage;