import React from 'react';
import './fonts.css';
import { Routes, Route } from 'react-router-dom';
import HomePage from './HomePage';
import JobPage from './JobPage';

function App() {
  return (
    <div className="App">
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/:jobID" element={<JobPage />} />
      </Routes>
    </div>
  );
}

export default App;