import React from 'react';
import { FaShareAlt, FaDownload, FaHome } from 'react-icons/fa';

function NavigationIcons() {
  return (
    <div className="flex items-center space-x-4"> 
      <FaShareAlt className="icon" />
      <FaDownload className="icon" />
      <FaHome className="icon" />
    </div>
  );
}

export default NavigationIcons;