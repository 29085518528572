import React, { useEffect, useRef } from 'react';
import Hls from 'hls.js';

function HLSVideoPlayer({ source }) {
  const videoRef = useRef(null);

  useEffect(() => {
    const video = videoRef.current;
    if (Hls.isSupported()) {
      const hls = new Hls();
      hls.loadSource(source);
      hls.attachMedia(video);
      hls.on(Hls.Events.MANIFEST_PARSED, () => {
        // Automatikus lejátszás, ha lehetséges
        video.play().catch(error => {
          // Hibakezelés, ha az automatikus lejátszás nem működik
          console.error('Automatikus lejátszás nem lehetséges:', error);
        });
      });
    } else if (video.canPlayType('application/vnd.apple.mpegurl')) {
      // iOS esetén, natív HLS támogatással
      video.src = source;
      video.addEventListener('loadedmetadata', () => {
        // Automatikus lejátszás, ha lehetséges
        video.play().catch(error => {
          // Hibakezelés, ha az automatikus lejátszás nem működik
          console.error('Automatikus lejátszás nem lehetséges:', error);
        });
      });
    }
  }, [source]);

  return (
    <div className="video-player-wrapper">
      <video ref={videoRef} controls className="video-player h-full w-full object-cover" />
    </div>
  );
}

export default HLSVideoPlayer;