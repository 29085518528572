import React, { useState, useEffect } from 'react';
import logoImage from '../../assets/WM_ReView_logo.png';
import darkLogoImage from '../../assets/WM_ReView_logo_dark.png'; // Sötét mód logója

function Logo() {
  const [isDarkMode, setIsDarkMode] = useState(window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches);

  useEffect(() => {
    const darkModeMediaQuery = window.matchMedia('(prefers-color-scheme: dark)');

    // Média lekérdezés változásának kezelője
    const handleDarkModeChange = (e) => {
      setIsDarkMode(e.matches);
    };

    // Eseménykezelő hozzáadása
    darkModeMediaQuery.addListener(handleDarkModeChange);

    // Takarítás a komponens eltávolításakor
    return () => {
      darkModeMediaQuery.removeListener(handleDarkModeChange);
    };
  }, []);

  return <img src={isDarkMode ? darkLogoImage : logoImage} alt="Logó" className="h-8" />;
}

export default Logo;