import React from 'react';
import Logo from './Logo';
import TitleSection from './TitleSection';
import HeaderRight from './HeaderRight';

function Header({ isLoggedIn, userName, mainTitle }) {
  const subTitle = "V01";

  return (
    <header className='grid grid-cols-[120px_minmax(auto,1fr)_auto] gap-4 items-center w-full'>
      <div className="col-start-1 row-start-1">
        <Logo />
      </div>
      <div className="col-start-2 col-end-3 row-start-1">
        <TitleSection mainTitle={mainTitle} subTitle={subTitle} />
      </div>
      <div className="col-start-3 col-end-4 row-start-1">
        <HeaderRight isLoggedIn={isLoggedIn} userName={userName} />
      </div>
      <style jsx>{`
        @media (max-width: 600px) {
          header {
            grid-template-columns: 120px 1fr;
            grid-template-rows: auto auto;
            padding-right: 2;
          }
          .col-start-2.col-end-3.row-start-1 {
            grid-column: 1 / -1;
            grid-row: 2;
          }
          .col-start-3.col-end-4.row-start-1 justify-self-end {
            grid-column: 2;
            grid-row: 1;
          }
        }
      `}</style>
    </header>
  );
}

export default Header;